import { asRouteMap } from '@kaliber/routing'
import { getRouteData } from './routeData'

const routeData = getRouteData()

export const routeMap = asRouteMap(
  {
    root: '',
    api: {
      path: 'api',

      v1: {
        path: 'v1',

        url: {
          path: 'document/path',
          data: requestHandlers => requestHandlers.determineDocumentPath,
        },

        jobs: {
          path: 'jobs',
          data: requestHandlers => requestHandlers.fetchJobs,
        },

        locations: {
          path: 'locations',
          data: requestHandlers => requestHandlers.fetchLocations,
        },

        search: {
          path: 'search',
        },

        placeCoordinate: {
          path: 'placecoordinate',
          data: requestHandlers => requestHandlers.fetchPlaceCoordinate,
        },

        placesAutocomplete: {
          path: 'placesautocomplete',
          data: requestHandlers => requestHandlers.placeAutocomplete,
        },

        auth: {
          path: 'auth',
          login: {
            path: 'login',
            data: requestHandlers => requestHandlers.login
          },
          userinfo: {
            path: 'userinfo',
            data: requestHandlers => requestHandlers.userInfo
          },
          logout: {
            path: 'logout',
            data: requestHandlers => requestHandlers.logout
          },
          handleSamlCallback: {
            path: 'callback',
            data: requestHandlers => requestHandlers.samlCallback
          },
        },

        smartRecruitersWebhookCallback: {
          path: 'smartrecruiters-webhook-callback',
          data: requestHandlers => requestHandlers.smartRecruitersWebhookCallback,
        }
      },

      sitemap: {
        path: 'sitemap',
        data: requestHandlers => requestHandlers.sitemap,
      },

      linkedInWithUtmParams: {
        path: 'linkedin',
        data: requestHandlers => requestHandlers.linkedInFeedWithUtmParams,
      },

      linkedInWithoutUtmParams: {
        path: 'linkedin-generic',
        data: requestHandlers => requestHandlers.linkedInFeedWithoutUtmParams,
      },

      indeed: {
        path: 'indeed',
        data: requestHandlers => requestHandlers.indeedFeed,
      },

      notFound: '*'
    },
    preview: 'preview',
    app: {
      path: ':language',
      data: routeData.app.data,
      home: {
        path: '',
        data: routeData.app.home.data,
      },
      jobs: {
        path: 'jobs',
        overview: {
          path: '',
          data: routeData.app.jobs.data
        },
        detail: {
          path: ':jobAdTitle/:jobAdId',
          data: routeData.app.jobs.detail.data,
        },
      },
      workWithHeartAndSoul: {
        path: 'work-with-heart-and-soul',
        page: {
          path: '',
          data: routeData.app.workWithHeartAndSoul.page.data,
        },
        timeline: {
          path: 'timeline',
          data: routeData.app.workWithHeartAndSoul.timeline.data,
        },
        about: {
          path: 'about',
          data: routeData.app.workWithHeartAndSoul.about.data,
        },
        childPage: {
          path: ':slug',
          data: routeData.app.workWithHeartAndSoul.childPage.data,
        },
      },
      page: {
        path: ':slug',
        data: routeData.app.page.data,
      },
      protectedPage: {
        path: 'internal',
        page: {
          path: ':slug',
          data: routeData.app.protectedPage.data,
        },
      },
      shops: {
        path: 'shops',
        page: {
          path: '',
          data: routeData.app.shops.page.data,
        },
        growth: {
          path: 'growth',
          data: routeData.app.shops.growth.data,
        },
        benefits: {
          path: 'benefits',
          data: routeData.app.shops.benefits.data,
        },
        faq: {
          path: 'faq',
          data: routeData.app.shops.faq.data,
        },
        mindOasis: {
          path: 'mind-oasis',
          data: routeData.app.shops.mindOasis.data,
        },
        houseOfRituals: {
          path: 'house-of-rituals',
          data: routeData.app.shops.houseOfRituals.data,
        },
        role: {
          path: ':slug',
          data: routeData.app.shops.role.data,
        }
      },
      instore: {
        path: 'instore/:title/:stepId',
        data: {
          ...routeData.app.instore.data,
          excludeFromSitemap: true
        },
      },
      offices: {
        path: 'offices',
        page: {
          path: '',
          data: routeData.app.offices.page.data,
        },
        growth: {
          path: 'growth',
          data: routeData.app.offices.growth.data,
        },
        department: {
          path: 'department/:slug',
          data: routeData.app.offices.department.page.data,
        },
        faq: {
          path: 'faq',
          data: routeData.app.offices.faq.page.data
        },
        interns: {
          path: 'interns',
          data: routeData.app.offices.interns.data,
        },
        benefits: {
          path: 'benefits',
          data: routeData.app.offices.benefits.data,
        },
      },
      lifeAtRituals: {
        path: 'life-at-rituals',
        page: {
          path: '',
          data: routeData.app.lifeAtRituals.page.data,
        },
        article: {
          path: ':slug',
          data: routeData.app.lifeAtRituals.article.data,
        },
      },
      cookiePolicy: {
        path: 'cookie-policy',
        data: routeData.app.cookiePolicy.data,
      },
      privacyPage: {
        path: 'privacy-statement',
        data: routeData.app.privacyPage.data,
      },
      impressumPage: {
        path: 'impressum',
        data: routeData.app.impressumPage.data,
      },
      instoreSearch: {
        path: 'instore-search',
        data: {
          ...routeData.app.instoreSearch.data,
          excludeFromSitemap: true
        },
      },
      notFound: {
        path: '*',
        data: routeData.app.notFound.data,
      }
    },
    admin: 'admin',
  },
  { trailingSlash: true }
)
